import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessState, BusinessTypes, Permission } from '../types/business.type';

// Define an initial state
const initialState: BusinessState = {
  business: {
    docs: [
      {
        _id: '',
        email: '',
        firstName: '',
        lastName: '',
        profileImageURL: '',
        blocked: false,
        createdBy: '',
        role: '',
        businessName: '',
        department: '',
        userID: '',
        businessID: {
          _id: '',
          lastInvoiceNumber: null,
          minIncomingInvoiceApprovalCount: null,
          minOutgoingInvoiceApprovalCount: null,
          minCreditApprovalCount: null,
          subAccountCode: '',
          email: '',
          name: '',
          directorsDetails: [
            {
              type: '',
              fullName: '',
              number: '',
              _id: '',
            },
          ],
          profileImageURL: '',
          blocked: false,
          createdBy: '',
          onBoardedBy: '',
          rating: null,
          reviewsCount: null,
          totalTransactions: null,
          type: '',
          phone: '',
          addresses: [
            {
              address: '',
              country: '',
              state: '',
              postalCode: '',
              addressType: '',
              _id: '',
            },
          ],
          industryType: '',
          companySize: '',
          status: '',
          rcNumber: '',
          kybDocument: [
            {
              name: '',
              documentURL: '',
              _id: '',
            },
          ],
          bankInfo: [
            {
              bankName: '',
              bankCode: '',
              accountName: '',
              accountNumber: '',
              _id: '',
            },
          ],
          socialMediaInfo: [],
          activatedDate: '',
          repsDetails: [
            {
              lastName: '',
              firstName: '',
              email: '',
              position: '',
              _id: '',
            },
          ],
          creditAssessment: [],
          createdAt: '',
          updatedAt: '',
        },
        createdAt: '',
        updatedAt: '',
      },
    ],
    totalDocs: null,
    limit: null,
    totalPages: null,
    page: null,
    pagingCounter: null,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
  permission: {
    _id: '',
    createdBy: '',
    updatedBy: '',
    businessID: '',
    permissions: [],
    name: '',
    editable: false,
    createdAt: '',
    updatedAt: '',
  },
};

// Create a slice containing the configuration of the state
// and the reducers functions
const businessReducer = createSlice({
  name: 'business',
  initialState,
  reducers: {
    resetBusinessState(state) {
      state = initialState;
      return state;
    },
    setBusiness(state, action: PayloadAction<BusinessTypes>) {
      state.business = {
        ...initialState.business,
        ...state.business,
        ...action.payload,
      };
      return state;
    },
    setPermission(state, action: PayloadAction<Permission>) {
      state.permission = {
        ...initialState.permission,
        ...state.permission,
        ...action.payload,
      };
      return state;
    },
    updateProfileImageURL(
      state,
      action: PayloadAction<{ profileImageURL: string; businessID: string }>
    ) {
      const { profileImageURL, businessID } = action.payload;
      const businessDoc = state.business.docs.find(
        (doc) => doc.businessID._id === businessID
      );
      if (businessDoc) {
        businessDoc.businessID.profileImageURL = profileImageURL;
      }
    },
  },
});

// Export each reducers function defined in createSlice
export const { resetBusinessState, setBusiness, setPermission, updateProfileImageURL } =
  businessReducer.actions;

// Export default the slice reducer
export default businessReducer.reducer;
