import React from 'react';
import { TransactionDoc } from '../../../../types/transaction.type';
import { formatCurrency } from '../../../../utils';
import { InvoiceDoc } from '../../../../types/invoice.type';
import Button from '../../../../Components/Form/Button';

type Props = {
  history: TransactionDoc[] | null;
  invoice: InvoiceDoc | undefined;
};

const InvoiceProof: React.FC<Props> = ({ history, invoice }) => {
  const handleClick = (id: string) => {
    const index = invoice?.paymentProof?.findIndex((item) => item === id);
    if (index !== undefined && index >= 0) {
      window.open(`${invoice?.fileURLs[index]}`, '_blank')?.focus();
    }
  };

  return (
    <div className="flex items-start justify-between gap-4 w-full mt-6">
      {history &&
        history
          .filter(
            (item) =>
              item?.verified && item?.amount && item?.paymentType === 'offline_payment'
          )
          .map((item) => (
            <div
              key={item.id}
              className="flex flex-wrap items-center justify-between gap-6 w-full"
            >
              <div>
                <p className="space-y-1 text-black/70 text-base font-medium">
                  Amount Paid{' '}
                </p>
                <h3 className="text-lg">
                  {' '}
                  <strong>
                    {' '}
                    {formatCurrency(
                      item?.currency === 'Dollars' ? 'usd' : item?.currency,
                      item?.amount
                    )}{' '}
                  </strong>{' '}
                </h3>
              </div>
              <div>
                <p className="space-y-1 text-black/70 text-base font-medium">
                  Outstanding Amount{' '}
                </p>
                <h3 className="text-center text-lg">
                  {' '}
                  <strong>
                    {formatCurrency(
                      invoice?.currency === 'Dollars' ? 'usd' : invoice?.currency,
                      invoice?.outstanding
                    )}{' '}
                  </strong>{' '}
                </h3>
              </div>
              <div className="max-w-xs max-[1200px]:max-w-[200px]">
                <p className="space-y-1 text-black/70 text-base font-medium">
                  {`${invoice?.sellerName} has made payment of ${formatCurrency(
                    item?.currency === 'Dollars' ? 'usd' : item?.currency,
                    item?.amount
                  )} `}{' '}
                </p>
                <Button
                  onClick={() => handleClick(item._id)}
                  className="text-[#0353A4] outline-none border-none text-center text-sm"
                  label="View Proof of Payment"
                  variant="transparent"
                />
              </div>
            </div>
          ))}
    </div>
  );
};

export default InvoiceProof;
