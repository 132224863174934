import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Icon, Icons } from '../../../../Components/Icon';
import CheckPermissions from '../../../../Components/Unauthorized/CheckPermissions';
import {
  useArchiveDept,
  useCreateDepartment,
  useGetDepartment,
  useUpdateDepartment,
} from '../../../../hooks/queries-and-mutations/settings';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import Loader from '../../../../Assests/loader.gif';
import { SlOptionsVertical } from 'react-icons/sl';
import { formatDate } from '../../../../utils';
import TextField from '../../../../Components/Form/TextField';
import Button from '../../../../Components/Form/Button';
import useOnClickOutside from '../../../../CustomHooks/useClickOutside';
import { AnimatePresence, motion } from 'framer-motion';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import DeleteModal from '../../../../Components/Modal/DeleteModal';

interface IData {
  name: string;
  departmentID: string;
  isEdit: boolean;
}

const EnhancedConfirmationModal = withCreatePortal(DeleteModal);

export default function Department() {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [getIdSelected, setGetIdSelected] = useState<string>(' ');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeIndex, setActiveIndex] = useState<string | null>();
  const [toggler, setToggler] = useState<boolean>(false);
  const modalRef = useRef(null);
  const { docs } = useAppSelector(selectBusiness);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [getDepartmentName, setGetDeptName] = useState<string | null>('');
  const { data, refetch } = useGetDepartment({
    businessId: docs[0].businessID._id,
    page: 1,
  });

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  const { mutate, isSuccess: isSaved, isError } = useCreateDepartment();

  const {
    mutate: update,
    isSuccess: isUpdated,
    isError: isUpdateError,
  } = useUpdateDepartment();

  const { mutateAsync: archiveDept } = useArchiveDept();

  const departments = useMemo(() => {
    if (data && data?.docs.length) {
      return data?.docs.map((dp) => ({
        name: dp.name ?? '',
        departmentId: dp._id ?? '',
        isEdit: false,
        createdAt: dp.createdAt,
      }));
    } else {
      return [];
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      departments,
    },
    validationSchema: Yup.object({
      departments: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Department name is required').nullable(),
        })
      ),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const [deptRoles, setDeptRoles] = useState(formik?.values?.departments);

  const addMoreDepartment = () => {
    setActiveIndex(null);
    if (docs[0].businessID.status === 'active') {
      const deptObj = {
        name: '',
        departmentId: '',
        isEdit: true,
      };
      const updatedDepts = [...formik.values.departments, deptObj];
      formik.setFieldValue('departments', updatedDepts);
    } else {
      toast.info('You need to verify your business first');
    }
  };

  useEffect(() => {
    if (isSaved && !isError) {
      setIsSubmitting(false);
      toast.success('Department created');
      refetch();
    } else if (isError) {
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSaved]);

  useEffect(() => {
    if (isUpdated && !isUpdateError) {
      setIsSubmitting(false);
      toast.success('Department updated');
      refetch();
    } else if (isUpdateError) {
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateError, isUpdated]);

  useEffect(() => {
    //Runs for when I add a new department
    setDeptRoles(formik.values.departments);
  }, [formik.values.departments, deptRoles]);

  const handleArchiveADept = async (id: string) => {
    archiveDept({ businessId: docs[0].businessID._id, id })
      .then(() => {
        toast.success('Department archived successfully');
        setDeptRoles((prev) => {
          const updatedDeptRoles = prev.filter(
            (dept) => dept.departmentId !== getIdSelected
          );
          return updatedDeptRoles;
        });
        refetch();
      })
      .catch((error) => {
        const err = error as AxiosError<Error>;
        toast.error(err.message ?? 'Sorry!, An error as occurred');
      });
    setGetDeptName(null);
    setGetIdSelected('');
  };

  const handleAddDept = async (departmentId: string, name: string, index: number) => {
    if (departmentId) {
      setIsSubmitting(true);
      const payload = {
        name: name,
        id: departmentId,
      };
      update({
        payload,
        businessId: docs[0].businessID?._id,
      });
      formik.setFieldValue(`depatments[${index}].isEdit`, false);
    } else {
      mutate(
        {
          name: formik.values.departments[index]?.name,
          businessId: docs[0].businessID?._id,
        },
        {
          onSuccess: (response) => {
            const newDepartment: IData = {
              name: response.name,
              departmentID: response._id,
              isEdit: false,
            };
            setDeptRoles((prev: any) => {
              const prevDepts = [...prev];
              prevDepts[index] = newDepartment;
              return prevDepts;
            });
          },
        }
      );
    }
  };
  const handleModalClose = () => {
    setActiveIndex(null);
    setToggler(false);
  };

  useOnClickOutside(modalRef, handleModalClose);

  return (
    <div className="w-full">
      <div className="p-8 max-[540px]:p-3">
        <CheckPermissions
          requiredPermissions={[
            'create_business_department',
            'update_business_department',
          ]}
        >
          <div className="py-5 border-b max-[580px]:pl-0">
            <p className="text-2xl font-semibold text-black font-inter">Department</p>
          </div>

          {isLoading ? (
            <div className="flex flex-col items-center justify-center mt-24">
              <img src={Loader} className="w-32" alt="" />
            </div>
          ) : (
            <div className="pb-5 mt-9">
              <div
                className={`rounded table-responsive overflow-x-auto ${
                  formik.values.departments.length === 1 ? 'h-[250px]' : 'h-auto'
                }`}
              >
                <table className="w-full mt-3 text-sm font-medium text-left text-black font-inter">
                  <thead className="bg-[#0353A4B0]  w-full text-white rounded-[15px] mb-3">
                    <tr className="rounded-[10px]">
                      <th scope="col" className="px-6 py-[25px]  rounded-tl-md">
                        S/N
                      </th>
                      <th scope="col" className="text-center py-[25px]">
                        Departments
                      </th>
                      <th scope="col" className="min-w-[120px] text-center py-[25px]">
                        Date Created
                      </th>
                      <th scope="col" className="text-center py-[25px] rounded-tr-md">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-subscriptionBg ">
                    {formik.values.departments.length >= 1 ? (
                      deptRoles?.map((department, index: number) => (
                        <tr key={index} className="py-4 rounded-bl-md rounded-br-md">
                          <th
                            scope="row"
                            className="p-5 px-6 font-medium text-textGray whitespace-nowrap"
                          >
                            {index + 1}
                          </th>
                          <AnimatePresence>
                            {department?.isEdit ? (
                              <motion.td
                                initial={{ opacity: 0, height: 0, y: 50 }}
                                animate={{
                                  opacity: 1,
                                  y: 0,
                                }}
                                exit={{ opacity: 0, height: 0, y: 50 }}
                              >
                                <div className=" mt-3">
                                  <TextField
                                    name={`departments[${index}].name`}
                                    type="text"
                                    value={formik.values.departments[index]?.name}
                                    disabled={!department.isEdit}
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      formik.setFieldValue(
                                        `departments[${index}].name`,
                                        e.target.value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      formik.handleChange(e);
                                      formik.setFieldValue(
                                        'departmentId',
                                        department.departmentId
                                      );
                                    }}
                                    error={
                                      formik.touched.departments
                                        ? formik.errors.departments?.[index] &&
                                          formik.errors.departments.length > 0
                                          ? JSON.parse(
                                              JSON.stringify(
                                                formik.errors.departments[index]
                                              )
                                            ).name
                                          : ''
                                        : ''
                                    }
                                    placeholder="Enter new department"
                                    className="bg-[#FFFFFF8C] text-black w-full"
                                  />
                                </div>
                              </motion.td>
                            ) : (
                              <td className="text-center">{department.name} </td>
                            )}
                          </AnimatePresence>
                          <td className="px-6 py-4 text-center">
                            {formatDate(department.createdAt)}{' '}
                          </td>
                          <td className="flex justify-center cursor-pointer text-center">
                            <AnimatePresence>
                              {department.isEdit ? (
                                <motion.div
                                  initial={{ opacity: 0, height: 0, y: 50 }}
                                  animate={{
                                    opacity: 1,
                                    y: 0,
                                    height: '100%',
                                  }}
                                  exit={{ opacity: 0, height: 0, y: 50 }}
                                  className="flex items-center gap-2 h-full mt-3"
                                >
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      if (department.isEdit && !department.departmentId) {
                                        setDeptRoles((prev) => {
                                          const updatedDept = prev.filter(
                                            (dept) => dept.isEdit !== true
                                          );
                                          formik.setFieldValue(
                                            'departments',
                                            updatedDept
                                          );
                                          return updatedDept;
                                        });
                                      } else if (
                                        department.isEdit &&
                                        department.departmentId
                                      ) {
                                        formik.setFieldValue(
                                          `departments[${index}].isEdit`,
                                          false
                                        );
                                      }
                                      setActiveIndex(null);
                                      setToggler(false);
                                    }}
                                    variant="transparent"
                                    size="sm"
                                    label="Cancel"
                                    className="h-10 mt-2 border border-black"
                                    disabled={isSubmitting || formik.isSubmitting}
                                    loading={isSubmitting || formik.isSubmitting}
                                  />
                                  <Button
                                    type="button"
                                    onClick={() => {
                                      handleAddDept(
                                        department.departmentId,
                                        department.name,
                                        index
                                      );
                                    }}
                                    variant="primary"
                                    size="sm"
                                    label={
                                      department.isEdit && department.departmentId
                                        ? 'Edit'
                                        : 'Save'
                                    }
                                    className="h-10 mt-2"
                                    disabled={isSubmitting || formik.isSubmitting}
                                    loading={isSubmitting || formik.isSubmitting}
                                  />
                                </motion.div>
                              ) : !department.isEdit && toggler ? (
                                <motion.div
                                  role="button"
                                  tabIndex={0}
                                  className={`p-2 relative`}
                                  onClick={() => {
                                    setToggler(false);
                                    setActiveIndex(null);
                                  }}
                                  initial={{ opacity: 0, height: 0, y: 50 }}
                                  animate={{
                                    opacity: 1,
                                    y: 0,
                                  }}
                                  exit={{ opacity: 0, height: 0, y: 50 }}
                                >
                                  <SlOptionsVertical />
                                  {activeIndex === department.departmentId && (
                                    <ul
                                      ref={modalRef}
                                      className="absolute left-[-60px] bottom-[-130px] z-40 flex flex-col items-start justify-between w-[170px] bg-white panel text-black"
                                    >
                                      <li className="py-2  w-full hover:bg-primary hover:text-white transition duration-1000 ease-out">
                                        {' '}
                                        <button
                                          type="button"
                                          onClick={() => {
                                            formik.setFieldValue(
                                              `departments[${index}].isEdit`,
                                              true
                                            );
                                          }}
                                          className="flex items-center gap-4 px-2 py-2 w-full"
                                        >
                                          <Icon name={Icons.EditPencil} />
                                          <span> Edit</span>
                                        </button>
                                      </li>
                                      <li className="py-2 w-full hover:bg-primary hover:text-white transition duration-1000 ease-out">
                                        {' '}
                                        <button
                                          className="flex items-center gap-4 px-2 py-2 w-full"
                                          type="button"
                                          onClick={() => {
                                            setDeleteModal(true);
                                            setGetIdSelected(department.departmentId);
                                            setGetDeptName(department.name);
                                          }}
                                        >
                                          <Icon name={Icons.Delete} />
                                          <span> Delete </span>
                                        </button>
                                      </li>
                                    </ul>
                                  )}
                                </motion.div>
                              ) : (
                                <button
                                  className="p-2 relative z-[1]"
                                  onClick={() => {
                                    setToggler(true);
                                    setActiveIndex(department.departmentId);
                                  }}
                                >
                                  <SlOptionsVertical />
                                </button>
                              )}
                            </AnimatePresence>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="rounded w-full p-10 bg-[#B9D6F224] border-2 border-solid border-red-600 ">
                        <td colSpan={4} className="text-lg font-medium font-inter w-full">
                          <div className="flex flex-col items-center gap-3 mx-auto mt-4 py-4">
                            <p>No Department added yet</p>
                            <div
                              role="button"
                              tabIndex={0}
                              className="flex items-center justify-center gap-1 mt-3 cursor-pointer w-full "
                              onClick={addMoreDepartment}
                            >
                              <Icon
                                name={Icons.Add}
                                fill="#0353A4"
                                width={16}
                                height={16}
                              />
                              <span className="text-[#0353A4] text-sm font-medium font-inter">
                                Add new Department
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div
            className="flex items-center mt-8 pb-9"
            role="button"
            tabIndex={0}
            onClick={addMoreDepartment}
          >
            <div className="flex items-center gap-1">
              <Icon name={Icons.Add} />
              <span className="min-w-[170px] text-sm font-medium text-[#000000]">
                Add another department
              </span>
            </div>
            <div className="line ml-3" />
          </div>
        </CheckPermissions>
      </div>
      {deleteModal && (
        <EnhancedConfirmationModal
          title="Delete this Department"
          content={`Are you sure you want to remove the ${getDepartmentName} department?`}
          actionText="Remove"
          cancelText="Cancel"
          cancelClassName="w-[200px] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-full min-w-fit px-3 py-3 text-base bg-[#061A40] rounded font-normal text-center text-white"
          onConfirm={() => {
            handleArchiveADept(getIdSelected);
          }}
          onCancel={() => {
            setDeleteModal(false);
          }}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
}
