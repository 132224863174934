import { useEffect, useState } from 'react';
import { Icon, Icons } from '../../../../../Components/Icon';
import Button from '../../../../../Components/Form/Button';
import {
  ApprovalTypeDoc,
  IChosenUsers,
  PayableApproval,
} from '../../../../../types/approval.type';
import { formatDate, getLevelName } from '../../../../../utils';
import withCreatePortal from '../../../../../Components/Hoc/withCreatePortal';
import AddPersonModal from '../../../../../Components/Modal/AddPersonModal';
import ConfirmModal from '../../../../../Components/Modal/ConfirmModal';
import {
  useAddPersonApproval,
  useBlockPersonApproval,
  useGetPerson,
} from '../../../../../hooks/queries-and-mutations/settings';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../../../store/hooks';
import { selectBusiness } from '../../../../../selectors/business-selector';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { AnimatePresence } from 'framer-motion';
import DropDown from '../../../../../Components/Animations/DropDown';

type IProps = {
  businessId: string;
  payable?: PayableApproval;
  refetch: () => void;
  groupedState: PayableApproval;
};

const EnhancedAddPersonModal = withCreatePortal(AddPersonModal);
const EnhancedConfirmModal = withCreatePortal(ConfirmModal);

export default function Payable({ businessId, refetch, groupedState }: IProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [showAddPerson, setShowAddPerson] = useState(false);
  const [fieldValue, setFieldValue] = useState<any>();
  const [level, setLevel] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmBlockModal, setShowConfirmBlockModal] = useState(false);
  const [isBlock, setIsBlock] = useState<'true' | 'false' | ''>('');
  const [selectedUser, setSelectedUser] = useState<ApprovalTypeDoc | null>(null);
  const [chosen, setChosen] = useState<IChosenUsers[]>([]);

  const { docs } = useAppSelector(selectBusiness);

  const { mutate, isSuccess, isError } = useAddPersonApproval();
  const {
    mutate: blockApproval,
    isSuccess: isBlockSuccess,
    isError: isBlockError,
  } = useBlockPersonApproval();

  const { data } = useGetPerson({ businessId, params: '' });

  const handleSubmit = () => {
    if (fieldValue.length) {
      setIsSubmitting(true);
      const payload = {
        approvals: {
          payable: {
            [level]: fieldValue,
          },
        },
        businessId,
      };
      mutate(payload);
    } else {
      toast.success('Select at least one person');
    }
  };

  const handleBlock = () => {
    if (selectedUser) {
      setIsSubmitting(true);
      const payload = {
        block: {
          receivable: {
            [`${isBlock}`]: [selectedUser?._id],
          },
        },
        businessId,
      };
      blockApproval(payload);
      setShowConfirmBlockModal(false);
      setSelectedUser(null);
    } else {
      toast.success('Select at least one person');
    }
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      refetch();
      toast.success('Approval granted successfully ');
      setIsSubmitting(false);
      setShowAddPerson(false);
      setShowConfirmModal(false);
    } else if (isError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess]);

  useEffect(() => {
    if (isBlockSuccess && !isBlockError) {
      refetch();
      toast.success(`User successfully ${isBlock === 'true' ? 'blocked' : 'unblocked'}`);
      setIsSubmitting(false);
      setShowAddPerson(false);
      setShowConfirmModal(false);
    } else if (isBlockError) {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlockError, isBlockSuccess]);

  useEffect(() => {
    Object.keys(groupedState).map((level: any) => {
      groupedState[level].sort((a: ApprovalTypeDoc, b: ApprovalTypeDoc) => {
        if (a.approvals.receivable && a.approvals.receivable.blocked) {
          return 1;
        }
        if (b.approvals.receivable && b.approvals.receivable.blocked) {
          return -1;
        }
        return 0;
      });
    });
  }, [groupedState]);

  return (
    <div>
      <div className="pb-4 border-b">
        <div
          role="button"
          tabIndex={0}
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setShowActive((prevState) => !prevState)}
        >
          <p className="text-xl font-semibold font-inter">Payable</p>
          <Icon name={showActive ? Icons.CaretUp : Icons.CaretDown} />
        </div>
        <AnimatePresence>
          {showActive && (
            <DropDown>
              <div className="mt-6">
                {Object.keys(groupedState).map((level) => {
                  return (
                    <div className="mt-10" key={level}>
                      <p className="my-2 font-medium text-black text-md">
                        {getLevelName(Number(level))}
                      </p>
                      <div className="rounded table-responsive">
                        <table>
                          <thead className="bg-[#0353A4B0] text-white rounded mb-3">
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th className="text-center min-w-[150px]">Date Created</th>
                              <th className="text-center">Action</th>
                              <th className="text-center  min-w-[150px]">Last Updated</th>
                            </tr>
                          </thead>
                          <tbody className="bg-[#B9D6F224]">
                            {groupedState[level].map((item) => {
                              return (
                                <tr key={item._id}>
                                  <td
                                    className={`whitespace-nowraps min-w-[150px] ${
                                      item.approvals.receivable.blocked
                                        ? 'line-through opacity-30'
                                        : ''
                                    }`}
                                  >{`${item.firstName} ${item.lastName}`}</td>
                                  <td
                                    className={`whitespace-nowraps ${
                                      item.approvals.receivable.blocked
                                        ? 'line-through opacity-30'
                                        : ''
                                    }`}
                                  >
                                    {item.email}
                                  </td>
                                  <td
                                    className={`whitespace-nowraps text-center ${
                                      item.approvals.receivable.blocked
                                        ? 'line-through opacity-30'
                                        : ''
                                    }`}
                                  >
                                    {formatDate(item.createdAt)}
                                  </td>
                                  <td className="p-3 space-x-3 text-center divide-x">
                                    {item.approvals.receivable.blocked ? (
                                      <button
                                        className=""
                                        type="button"
                                        onClick={() => {
                                          setShowConfirmBlockModal(true);
                                          setIsBlock('false');
                                          setSelectedUser(item);
                                        }}
                                      >
                                        {<AiOutlineEye />}
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setShowConfirmBlockModal(true);
                                          setIsBlock('true');
                                          setSelectedUser(item);
                                        }}
                                        className=" text-red-600"
                                      >
                                        <AiOutlineEyeInvisible />
                                      </button>
                                    )}
                                  </td>
                                  <td
                                    className={`whitespace-nowraps text-center ${
                                      item.approvals.receivable.blocked
                                        ? 'line-through opacity-30'
                                        : ''
                                    }`}
                                  >
                                    {formatDate(item.updatedAt)}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="flex flex-col items-end">
                        <Button
                          type="button"
                          variant="primary"
                          size="custom"
                          label="Add person"
                          loading={isSubmitting}
                          disabled={isSubmitting}
                          className="px-5 py-3 mt-5"
                          onClick={() => {
                            setLevel(Number(level));
                            setShowAddPerson(true);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                {!Object.keys(groupedState).length && (
                  <div className="rounded table-responsive">
                    <table>
                      <thead className="bg-[#0353A4B0] text-white rounded mb-3">
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Date Created</th>
                          <th className="text-center">Action</th>
                          <th>Last Updated</th>
                        </tr>
                      </thead>
                    </table>
                    <tbody className="rounded w-full p-10 bg-[#B9D6F224] flex flex-col items-center justify-center">
                      <tr className="text-lg font-medium font-inter">
                        No person added yet
                      </tr>
                      <tr>
                        <div
                          role="button"
                          tabIndex={0}
                          className="flex items-center gap-1 mt-3 cursor-pointer"
                          onClick={() => {
                            if (docs[0].businessID.status === 'active') {
                              setLevel(1);
                              setShowAddPerson(true);
                            } else {
                              toast.info('You need to verify your business');
                            }
                          }}
                        >
                          <Icon name={Icons.Add} fill="#0353A4" width={16} height={16} />
                          <span className="text-[#0353A4] text-sm font-medium font-inter">
                            Add new user
                          </span>
                        </div>
                      </tr>
                    </tbody>
                  </div>
                )}
                {groupedState[1] && Object.keys(groupedState[1]).length > 0 && (
                  <div className="mt-5">
                    <div className="flex items-center mt-5 pb-9">
                      <div
                        className="flex items-center gap-1"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          setLevel(Object.keys(groupedState).length + 1);
                          setShowAddPerson(true);
                        }}
                      >
                        <Icon name={Icons.Add} />
                        <span className="text-sm min-w-[120px] font-medium text-[#000000]">
                          Add another level
                        </span>
                      </div>
                      <div className="ml-1 line" />
                    </div>
                  </div>
                )}
              </div>
            </DropDown>
          )}
        </AnimatePresence>
      </div>

      {showAddPerson && (
        <EnhancedAddPersonModal
          onCancel={() => {
            setShowAddPerson(false);
            setChosen([]);
          }}
          onSubmit={(payload: string[]) => {
            setFieldValue(payload);
            setShowAddPerson(false);
            setShowConfirmModal(true);
          }}
          businessId={businessId ?? ''}
          persons={data}
          setChosen={setChosen}
        />
      )}

      {showConfirmModal && (
        <EnhancedConfirmModal
          content={`Are you sure you want to grant ${
            chosen && chosen?.length > 1 ? 'these people this' : `${chosen[0].name} this`
          } level of approval?`}
          onConfirm={() => {
            handleSubmit();
          }}
          onCancel={() => {
            setShowConfirmModal(false);
            setChosen([]);
          }}
          cancelText="Cancel"
          actionText="Yes, Save"
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#061A40] rounded font-normal text-white"
          widthClass={30}
        />
      )}

      {showConfirmBlockModal && selectedUser && (
        <EnhancedConfirmModal
          content={`Are you sure you want to ${
            isBlock === 'true' ? 'block' : 'unblock'
          } ${
            selectedUser?.firstName + ' ' + selectedUser?.lastName
          } from this approval level?`}
          onConfirm={() => {
            handleBlock();
          }}
          onCancel={() => {
            setShowConfirmBlockModal(false);
            setSelectedUser(null);
          }}
          cancelText="Cancel"
          actionText={` ${isBlock === 'true' ? 'Block' : 'Unblock'}`}
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#AA1414] rounded font-normal text-white"
          widthClass={30}
        />
      )}
    </div>
  );
}
