import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

type Props = {
  chartData: { month: string; amount: number }[];
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'top' as const,
    },
  },
};

export default function Chart({ chartData }: Props) {
  const labels = chartData.map(
    ({ month }) => month.charAt(0).toUpperCase() + month.slice(1)
  ); // ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const data = {
    labels,
    datasets: [
      {
        data: chartData.map(({ amount }) => amount), // [1, 3, 2, 7, 1, 3, 2, 7, 1, 3, 2, 7],
        borderColor: 'rgba(3, 83, 164, 1)',
        backgroundColor: 'rgba(3, 83, 164, 0)',
      },
    ],
  };

  return (
    <Line options={options} data={data} style={{ width: '100%', height: '300px' }} />
  );
}
