import { useCallback, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import Button from '../../../../Components/Form/Button';
import Avatar from '../../../../Assests/avatar-1.png';
import ConfirmModal from '../../../../Components/Modal/ConfirmModal';
import SuccessModal from '../../../../Components/Modal/SuccessModal';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { InvoiceDto } from '../../../../types/invoice.type';
import { format } from 'date-fns';
import { formatCurrency } from '../../../../utils';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import {
  useCreateInvoice,
  useUpdateInvoice,
} from '../../../../hooks/queries-and-mutations/invoice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type Props = {
  form: InvoiceDto;
  vendor: any;
  selectedInvoiceId: string;
  setSelectedInvoiceId: (prev: string) => void;
  setShowErr: (prev: string) => void;
};

const EnhancedSuccessModal = withCreatePortal(SuccessModal);
const EnhancedConfirmModal = withCreatePortal(ConfirmModal);

export default function InvoicePreview({
  form,
  vendor,
  selectedInvoiceId,
  setSelectedInvoiceId,
  setShowErr,
}: Props) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDraftSuccessModal, setShowDraftSuccessModal] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dueDateError, setDueDateError] = useState(false);
  const navigate = useNavigate();

  const { docs } = useAppSelector(selectBusiness);
  const { mutate, isSuccess, isError } = useCreateInvoice();
  const {
    mutateAsync: update,
    isSuccess: isUpdated,
    isError: errorUpdating,
  } = useUpdateInvoice();

  const calculateAmount = (price: number, quantity: number) => {
    return price * quantity;
  };

  const getTotalAmount = useCallback(() => {
    let total = 0;
    form.products.forEach((product: any) => {
      total += calculateAmount(product.price, product.quantity);
    });
    return total;
  }, [form.products]);

  const getDiscount = () => {
    const result =
      form.discountType === 'Percentage'
        ? (Number(form.discount) / 100) * getTotalAmount()
        : form.discount;
    return result;
  };

  const getTax = () => {
    const result =
      form.taxType === 'Percentage'
        ? (Number(form.tax) / 100) * getTotalAmount()
        : form.tax;
    return result;
  };

  const finalAmount = useMemo(() => {
    let disam = 0;
    let taxam = 0;
    if (form?.discountType === 'Percentage') {
      disam = (Number(form.discount) / 100) * getTotalAmount();
    } else {
      disam = Number(form.discount);
    }
    if (form.taxType === 'Percentage') {
      taxam = (Number(form.tax) / 100) * getTotalAmount();
    } else {
      taxam = Number(form.tax);
    }
    const amountandDiscount = getTotalAmount() - disam;
    const result = amountandDiscount + Number(taxam);
    return result;
  }, [form, getTotalAmount]);

  const handleSubmit = (status: boolean) => {
    if (!form.dueDate) {
      setDueDateError(true);
      setShowErr('dueDate');
      return;
    }
    const productsStuff = form?.products?.map((res) => res?.product?.value);
    if (productsStuff[0]?.length < 1) {
      toast.error('You have not added any product');
      setShowErr('productError');
      return;
    }
    setShowErr('');
    setIsDraft(status);
    setIsSubmitting(true);

    const transformedOptions = form.products.map((option) => ({
      product: option.product.value,
      quantity: option.quantity.toString(),
    }));

    const payload = {
      number: form.invoiceNumber,
      sellerID: selectedInvoiceId ? form?.sellerID : vendor?.vendorID?._id,
      address: form.vendorAddress,
      country: form.vendorCountry,
      state: form.vendorCity,
      date: form.date,
      requiredEnd: form.dueDate,
      products: transformedOptions,
      note: form.note,
      bankName: docs[0].businessID.bankInfo[0]?.bankName,
      bankAccountName: docs[0].businessID.bankInfo[0]?.accountName,
      bankAccountNumber: docs[0].businessID.bankInfo[0]?.accountNumber,
      currency: form.currency,
      tax: {
        value: form.tax?.toString() ?? 0,
        type: form.taxType?.toLocaleLowerCase()
          ? form.taxType?.toLocaleLowerCase()
          : 'percentage',
      },
      discount: {
        value: form.discount?.toString() ?? 0,
        type: form.discountType?.toLocaleLowerCase()
          ? form.discountType?.toLocaleLowerCase()
          : 'percentage',
      },
      createdBy: selectedInvoiceId ? form.createdBy : '',
      businessID: selectedInvoiceId ? form.businessID : '',
      sellerName: selectedInvoiceId ? form.vendorName : '',
      email: selectedInvoiceId ? form.vendorEmail : '',
    };

    const updatedPayload = {
      ...payload,
      tax: {
        value: form.tax?.toString() ?? 0,
        type: form.taxType?.toLocaleLowerCase(),
      },
      discount: {
        value: form.discount?.toString() ?? 0,
        type: form.discountType?.toLocaleLowerCase(),
      },
    };
    if (selectedInvoiceId) {
      update({
        businessId: docs[0].businessID?._id,
        updatedPayload,
        invoiceId: selectedInvoiceId,
      })
        .then(() => {
          setIsSubmitting(false);
          setShowSuccessModal(true);
          setSelectedInvoiceId('');
        })
        .catch(() => {
          setIsSubmitting(false);
          setSelectedInvoiceId('');
          toast.error('Failed to update invoice');
        });
    } else {
      mutate({ businessId: docs[0].businessID?._id, payload, status });
    }
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setIsSubmitting(false);
      if (isDraft) {
        setShowDraftSuccessModal(true);
      } else {
        setShowConfirmModal(false);
        setShowSuccessModal(true);
      }
    } else if (isError) {
      setIsSubmitting(false);
    }
  }, [isDraft, isError, isSuccess]);

  useEffect(() => {
    if (isUpdated && errorUpdating) {
      setIsSubmitting(false);
      setShowSuccessModal(true);
      setSelectedInvoiceId('');
      if (isDraft) {
        setShowDraftSuccessModal(true);
        setSelectedInvoiceId('');
      } else {
        setShowConfirmModal(false);
        setShowSuccessModal(false);
        setSelectedInvoiceId('');
      }
    } else if (!errorUpdating) {
      setIsSubmitting(false);
      setSelectedInvoiceId('');
    }
  }, [isUpdated, errorUpdating, isDraft]);

  const formatDate = (date: string | number | Date) => {
    if (!date) return '';
    const formattedDate = format(new Date(date), 'dd, MMM yyyy');
    return isNaN(new Date(formattedDate).getTime()) ? '' : formattedDate;
  };

  return (
    <div>
      <div className="flex flex-wrap items-center justify-between gap-[30px] max-[670px]:mt-[25px]">
        <Breadcrumb text="Invoices" subText="Create invoice" innerText="View invoice" />
        <div className="flex items-center gap-3">
          <Button
            type="button"
            variant="transparent"
            size="custom"
            label="Save as Draft"
            className="px-5 py-2.5 border border-[#0353A4] text-sm bg-transparent text-[#0353A4]"
            onClick={() => handleSubmit(true)}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
          <Button
            type="button"
            variant="primary"
            size="custom"
            label="Submit for Approval"
            className="px-5 py-2.5 text-sm"
            onClick={() => setShowConfirmModal(true)}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </div>
      </div>
      {dueDateError && (
        <div className="mt-2 ml-2 text-red-500">Please select a due date.</div>
      )}

      <div className="panel mt-9 py-12 rounded-[20px] bg-white">
        <div className="flex flex-wrap items-center justify-between gap-4 p-5 px-4 pb-6 border-b">
          <div className="flex items-center gap-6">
            <div className="shrink-0">
              <img
                className="object-cover w-20 h-20 rounded-full saturate-50 group-hover:saturate-100"
                src={docs[0]?.businessID.profileImageURL ?? Avatar}
                alt="avatar"
                onError={(el) => {
                  el.currentTarget.onerror = null;
                  el.currentTarget.src = Avatar;
                }}
              />
            </div>
            <p className="text-xl font-medium font-inter">{docs[0]?.businessID.name}</p>
          </div>

          <div className="px-4 text-right max-[540px]:ml-auto">
            <div className="mt-6 space-y-1 text-white-dark">
              <div>{docs[0]?.businessID.addresses[0].address}</div>
              <div>{docs[0]?.businessID.addresses[0].state}</div>
              <div>{docs[0]?.businessID.phone}</div>
              <div>{docs[0]?.businessID.email}</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-wrap justify-between gap-6 p-5 px-4 lg:flex-row mt-14">
          <div className="flex-1">
            <div className="space-y-1 text-base font-medium text-black/70">
              <div className="font-semibold text-black">Invoice to</div>
              <div>{form?.vendorName}</div>
              <div>{form?.vendorAddress}</div>
              <div>{form?.vendorCity}</div>
              <div>{vendor?.contactEmail}</div>
              <div>{vendor?.contactPhone}</div>
            </div>
          </div>
          <div className="px-4 text-right">
            <div className="space-y-1 text-white-dark">
              <div className="font-semibold text-black/70"></div>
              <div>Invoice no: {form?.invoiceNumber}</div>
              <div>Date: {form?.date ? formatDate(form?.date) : ''}</div>
              <div>
                Due date:{' '}
                {form?.dueDate ? (
                  formatDate(form?.dueDate)
                ) : (
                  <span className="text-xs text-red-500">Please select a due date</span>
                )}
              </div>

              <div>Terms of trade: {vendor?.termsOfTrade}</div>
            </div>
          </div>
        </div>
        {/* Table */}
        <div className="mt-6 overflow-auto">
          <table>
            <thead className="bg-[#0353A4B0] text-white rounded mb-3">
              <tr>
                <th>Product</th>
                <th>Description</th>
                <th className="min-w-[120px]">Unit price</th>
                <th>Quantity</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody className="bg-[#B9D6F224]">
              {form?.products.map((item) => (
                <tr key={item._id}>
                  <td className="whitespace-nowrap">{item.product.label}</td>
                  <td className="whitespace-nowrap">{item.productDescription}</td>
                  <td className="whitespace-nowrap">
                    {formatCurrency(item.product.currency, item.price)}
                  </td>
                  <td className="whitespace-nowrap">{item.quantity}</td>
                  <td className="whitespace-nowrap">
                    {formatCurrency(item.product.currency, item.price * item.quantity)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-end min-[800px]:px-10 max-[800px]:px-4 pb-10 min-[800px]:mx-10 max-[800px]:mx-4 mb-10 border-b mt-9">
          <div className="sm:w-2/5 min-w-[300px] max-[350px]:min-w-[200px]">
            <div className="flex items-center justify-between">
              <div>Subtotal</div>
              <div>{formatCurrency(form?.currency, getTotalAmount())}</div>
            </div>
            <div className="flex items-center justify-between mt-4">
              <div>Discount</div>
              <div>
                {formatCurrency(form?.currency, !getDiscount() ? 0 : getDiscount())}
              </div>
            </div>
            <div className="flex items-center justify-between mt-4">
              <div>Tax</div>
              <div>{formatCurrency(form?.currency, !getTax() ? 0 : getTax())}</div>
            </div>
            <div className="mt-4 py-6 rounded-2xl bg-[#B9D6F2] p-4 flex items-center justify-between font-semibold">
              <div>Amount Due</div>
              <div>{formatCurrency(form?.currency, !finalAmount ? 0 : finalAmount)}</div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-between gap-[30px] p-8 px-10">
          <div className="text-base font-medium text-black font-inter">
            <p className="font-semibold">Note</p>
            {form?.note ?? ''}
          </div>
          <div className="min-w-[350px]">
            <p className="font-semibold">Payment method</p>
            <div className="flex items-center justify-between gap-[20px] mt-5">
              <div className="min-w-[100px]">Bank name</div>
              <div>{docs[0].businessID?.bankInfo[0]?.bankName}</div>
            </div>
            <div className="flex items-center justify-between mt-4 gap-[20px]">
              <div className="min-w-[130px]">Account number</div>
              <div>{docs[0].businessID?.bankInfo[0]?.accountNumber}</div>
            </div>
            <div className="flex items-center justify-between mt-4 gap-[20px]">
              <div className="min-w-[120px]">Account name</div>
              <div>{docs[0].businessID?.bankInfo[0]?.accountName}</div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmModal && (
        <EnhancedConfirmModal
          isSubmitting={isSubmitting}
          widthClass={35}
          content={'Are you sure you want to submit this invoice?'}
          onConfirm={() => {
            handleSubmit(false);
          }}
          onCancel={() => setShowConfirmModal(false)}
          cancelText="Cancel"
          actionText="Submit"
          cancelClassName="w-[7rem] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-[7rem] px-5 py-2.5 text-sm bg-[#061A40] rounded font-normal text-white"
        />
      )}
      {showSuccessModal && (
        <EnhancedSuccessModal
          title="Invoice submitted successfully "
          description="Your invoice has been submitted"
          cancelText="Back"
          onCancel={() => {
            setShowSuccessModal(false);
            navigate('/invoices');
          }}
        />
      )}
      {showDraftSuccessModal && (
        <EnhancedSuccessModal
          title="Invoice saved to draft"
          description={
            isUpdated
              ? 'Your invoice has been updated successfully'
              : 'Your invoice has been saved'
          }
          cancelText="Back"
          onCancel={() => {
            setShowDraftSuccessModal(false);
            navigate('/invoices-draft');
          }}
          isDraft
        />
      )}
    </div>
  );
}
