import { useState, useRef, useEffect } from 'react';
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Icon, Icons } from '../Components/Icon';
import Avatar from '../Assests/avatar.png';
import Logo from '../Assests/newLogos/WordmarkTwo.webp';
import { determinePathName } from '../utils';
import { logout } from '../thunks/account-thunk';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectBusiness } from '../selectors/business-selector';
import ImageWithFallback from '../Components/ImageDisplay/ImageDisplay';
import useOnClickOutside from '../CustomHooks/useClickOutside';
import { TbLogout2 } from 'react-icons/tb';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import FadeIn from '../Components/Animations/FadeIn';
import { TfiAngleLeft, TfiAngleRight } from 'react-icons/tfi';
import { RiMenu3Fill } from 'react-icons/ri';
import { MdClose } from 'react-icons/md';
import { useInactivity } from '../thunks/trackUserTime';

export default function UserLayout() {
  const [showNav, setShowNav] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { docs } = useAppSelector(selectBusiness);

  const { resetTimer } = useInactivity();

  const ref = useRef<any>();

  const onClose = () => {
    setShowNav(false);
  };

  useOnClickOutside(ref, onClose);

  const renderNavLink = (icon: Icons, navName: string, path: string): JSX.Element => (
    <li className="nav-item">
      <NavLink
        to={path}
        className={`group ${determinePathName(pathname) === path ? 'active' : ''}`}
      >
        <div className="flex items-center pl-6">
          <Icon
            name={icon}
            fill={determinePathName(pathname) === path ? '#000000' : '#FFFFFF'}
          />
          <span
            className={`ltr:pl-3 rtl:pr-3 ${
              determinePathName(pathname) === path ? 'text-black' : 'text-white/80'
            }`}
          >
            {navName}
          </span>
        </div>
      </NavLink>
    </li>
  );

  const onNavigate = (path: string) => {
    setShowNav(false);
    navigate(path);
  };

  const handleLogout = () => {
    dispatch(logout());
    onNavigate('/auth/login');
  };

  const variant: Variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };

  useEffect(() => {
    setShowMobileMenu(false);
  }, [pathname]);

  return (
    <div className="relative grid min-[970px]:grid-cols-[260px_auto] max-[970px]:grid-cols-[80px_auto] max-[640px]:grid-cols-[auto] w-full min-h-[100vh] h-[100vh] overflow-hidden text-black">
      <nav className="sidebar overflow-hidden h-screen shadow-[5px_0_25px_0_rgba(94,92,154,0.1)]  transition-all duration-300 bg-[#0353A4] max-[970px]:hidden ">
        <div className="h-full bg-[#0353A4] text-white  relative space-y-0.5 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] overflow-x-hidden pb-28">
          <div className="h-[70px] ">
            <Link to="/dashboard">
              <img className="w-[80%] max-h-[100%] object-cover" src={Logo} alt="logo" />
            </Link>
          </div>
          <ul className="flex flex-col justify-between gap-[60px] h-full font-semibold mt-14">
            <div className="relative text-sm font-normal text-white mt-9">
              <li className="text-base font-medium nav-item text-white/80 font-inter">
                <ul>
                  {renderNavLink(Icons.Dashboard, 'Dashboard', '/dashboard')}
                  {renderNavLink(Icons.Vendor, 'Vendors', '/vendors')}
                  {renderNavLink(Icons.Invoices, 'Invoices', '/invoices')}
                  {renderNavLink(Icons.Product, 'Products', '/products')}
                  {renderNavLink(Icons.Transaction, 'Transactions', '/transactions')}
                  {renderNavLink(Icons.Audit, 'Audit Trail', '/audit-trail')}
                  {renderNavLink(Icons.Settings, 'Settings', '/settings')}
                </ul>
              </li>
            </div>
            <li className="mt-auto ms-9">
              <div
                className="cursor-pointer"
                role="button"
                tabIndex={0}
                onClick={handleLogout}
              >
                <div className="flex items-center w-2/3 p-3 px-5 border rounded-lg">
                  <Icon name={Icons.Logout} />
                  <span className="font-normal text-white ltr:pl-3 rtl:pr-3">Logout</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      {/* Navbar for mobile */}
      <AnimatePresence>
        {showMobileMenu && (
          <motion.nav
            initial={{ translateX: '-100%', opacity: 0, zIndex: '99' }}
            animate={{ translateX: '0%', opacity: 1, zIndex: '99' }}
            exit={{ translateX: '-100%', opacity: 1, zIndex: '99' }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="sidebar fixed z-99 left-0 top-0 w-[300px] max-[450px]:w-[60%] h-full shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] bg-[#0353A4]"
          >
            <div className="h-full bg-[#0353A4] text-white  relative space-y-0.5 overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] overflow-x-hidden pb-28">
              <div className="h-[70px] ">
                <Link to="/dashboard">
                  <img
                    className="w-[80%] max-h-[100%] object-cover"
                    src={Logo}
                    alt="logo"
                  />
                </Link>
              </div>
              <ul className="flex flex-col justify-between gap-[60px] h-full font-semibold mt-14">
                <div className="relative text-sm font-normal text-white mt-9">
                  <li className="text-base font-medium nav-item text-white/80 font-inter">
                    <ul>
                      {renderNavLink(Icons.Dashboard, 'Dashboard', '/dashboard')}
                      {renderNavLink(Icons.Vendor, 'Vendors', '/vendors')}
                      {renderNavLink(Icons.Invoices, 'Invoices', '/invoices')}
                      {renderNavLink(Icons.Product, 'Products', '/products')}
                      {renderNavLink(Icons.Transaction, 'Transactions', '/transactions')}
                      {renderNavLink(Icons.Audit, 'Audit Trail', '/audit-trail')}
                      {renderNavLink(Icons.Settings, 'Settings', '/settings')}
                    </ul>
                  </li>
                </div>
                <li className="mt-auto ms-9">
                  <div
                    className="cursor-pointer"
                    role="button"
                    tabIndex={0}
                    onClick={handleLogout}
                  >
                    <div className="flex items-center w-2/3 p-3 px-5 border rounded-lg">
                      <Icon name={Icons.Logout} />
                      <span className="font-normal text-white ltr:pl-3 rtl:pr-3">
                        Logout
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
              <AnimatePresence>
                {showMobileMenu && (
                  <motion.button
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={variant}
                    className="max-[640px]:hidden absolute top-[45%] left-[240px] bg-black/50 w-[50px] rounded-md z-[999] p-3"
                    onClick={() => {
                      setShowMobileMenu((prevState) => !prevState);
                    }}
                  >
                    <TfiAngleLeft color="white" size={24} />
                  </motion.button>
                )}
              </AnimatePresence>
            </div>
          </motion.nav>
        )}
      </AnimatePresence>
      <div className="relative min-[970px]:hidden max-[970px]:block max-[640px]:hidden h-[100vh] bg-[#0353A4] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] border border-white/30">
        <AnimatePresence>
          {!showMobileMenu && (
            <motion.button
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={variant}
              className="absolute top-[45%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 z-[999] bg-black/50 w-[50px] rounded-md p-3 "
              onClick={() => setShowMobileMenu((prevState) => !prevState)}
            >
              <TfiAngleRight color="white" size={24} />
            </motion.button>
          )}
        </AnimatePresence>
      </div>

      {/* end sidebar section */}
      <div className="bg-onboardingBg overflow-y-scroll">
        <div className="w-[95%] mx-auto ">
          <header className="!w-full sticky top-0 z-40">
            <div className="shadow-sm  ">
              <div className="relative z-30 flex items-center justify-between w-full  px-5 bg-white py-7 rounded-2xl">
                <div className="hidden ltr:mr-2 rtl:ml-2 sm:block">
                  <p className="text-2xl font-medium text-black font-inter">
                    {docs[0]?.businessName}
                  </p>
                </div>
                <div className="flex justify-between items-center gap-4" ref={ref}>
                  <Icon name={Icons.Bell} />
                  <div className="flex-shrink-0 pl-5 border-l dropdown">
                    <div
                      role="button"
                      tabIndex={0}
                      className="relative cursor-pointer group"
                      onClick={() => {
                        setShowNav((prevState) => !prevState);
                        resetTimer();
                      }}
                    >
                      <ImageWithFallback
                        src={
                          docs[0]?.businessID?.profileImageURL
                            ? docs[0]?.businessID?.profileImageURL
                            : docs[0].profileImageURL
                            ? docs[0].profileImageURL
                            : Avatar
                        }
                        alt="Image Description"
                        className="object-cover rounded-full w-[50px] h-[50px] saturate-50 group-hover:saturate-100"
                      />
                    </div>

                    <AnimatePresence>
                      {showNav && (
                        <FadeIn>
                          <ul className="z-50 top-14 w-[270px] !py-0 font-semibold text-dark -right-14 max-[640px]:left-[-60px] bg-white/30">
                            <li
                              className="cursor-pointer"
                              onClick={() => onNavigate('/profile')}
                            >
                              <div className="flex items-center px-4 py-4">
                                <div className="flex-none">
                                  <img
                                    className="object-cover rounded-md h-11 w-11"
                                    src={Avatar}
                                    alt="avatar"
                                  />
                                </div>
                                <div className="truncate ltr:pl-4 rtl:pr-4">
                                  <h4 className="text-base font-medium font-inter">
                                    {docs[0]?.businessName}
                                  </h4>
                                  <span className="text-xs text-black/50">
                                    {docs[0]?.email}
                                  </span>
                                </div>
                              </div>
                            </li>
                            <li className="border-t border-white-light">
                              <p className="font-inter font-medium text-[10px] p-4">
                                Switch accounts
                              </p>
                              <div
                                role="button"
                                tabIndex={0}
                                className="flex items-center gap-3 p-3 text-sm font-medium cursor-pointer font-inter  hover:bg-hoverBgColor hover:text-black transition duration-1000 ease-out"
                                onClick={() => onNavigate('switch-account')}
                              >
                                <Icon name={Icons.Add} />
                                Switch account
                              </div>
                              <div
                                role="button"
                                tabIndex={0}
                                className="flex items-center gap-3 p-3 text-red-600 text-sm font-medium cursor-pointer font-inter  hover:bg-hoverBgColor transition duration-1000 ease-out"
                                onClick={() => {
                                  handleLogout();
                                }}
                              >
                                <TbLogout2 size={18} />
                                Log out
                              </div>
                            </li>
                          </ul>
                        </FadeIn>
                      )}
                    </AnimatePresence>
                  </div>
                  <div
                    role="button"
                    tabIndex={0}
                    className="cursor-pointer"
                    onClick={() => setShowNav((prevState) => !prevState)}
                  >
                    {showNav ? (
                      <Icon name={Icons.CaretUp} />
                    ) : (
                      <Icon name={Icons.CaretDown} />
                    )}
                  </div>
                </div>
                {/* Button to open and close for nav for mobile */}
                <div className="min-[640px]:hidden max-[640px]:block">
                  <button
                    className="mt-3"
                    onClick={() => setShowMobileMenu((prevState) => !prevState)}
                  >
                    {showMobileMenu ? (
                      <MdClose color="black" size={24} />
                    ) : (
                      <RiMenu3Fill color="black" size={24} />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </header>

          <div className="h-[100%] overflow-hidden">
            <div className="pt-5 !pb-[200px] ">
              {/* start main content section */}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
