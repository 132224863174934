import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Icon, Icons } from '../../../../../Components/Icon';
import CustomSelect from '../../../../../Components/Form/CustomSelect';
import TextField from '../../../../../Components/Form/TextField';
import Button from '../../../../../Components/Form/Button';
import { BusinessId, KYBDto } from '../../../../../types/business.type';
import { useGetBanks } from '../../../../../hooks/queries-and-mutations/business';
import { getBankDetails } from '../../../../../service/business';

type Props = {
  onSave: (values: KYBDto) => void;
  business: BusinessId;
};

export default function EditCompanyBank({ onSave, business }: Props) {
  const { data: bankList } = useGetBanks();
  const [loadingSpinners, setLoadingSpinners] = useState<boolean[]>([]);

  const banks = useMemo(() => {
    if (business && (business?.bankInfo ?? []).length) {
      return business?.bankInfo.map((bk) => ({
        bank: { value: bk.bankCode, label: bk.bankName },
        accountNumber: bk.accountNumber,
        accountName: bk.accountName,
      }));
    } else {
      return [
        {
          bank: { value: '', label: '' },
          accountNumber: '',
          accountName: '',
        },
      ];
    }
  }, [business]);

  const formik = useFormik({
    initialValues: {
      banks,
    },
    validationSchema: Yup.object({
      banks: Yup.array().of(
        Yup.object().shape({
          bank: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .nullable()
            .required('Please select a bank'),
          accountNumber: Yup.string().required('Account number is required').nullable(),
          accountName: Yup.string().required('Account name is required').nullable(),
        })
      ),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      onSave(values as KYBDto);
    },
  });

  const addMoreBank = () => {
    formik.values.banks.push({
      bank: { value: '', label: '' },
      accountNumber: '',
      accountName: '',
    });
    formik.setFieldValue('banks', [...formik.values.banks]);
  };

  const handleGetBankDetails = async (index: number) => {
    if (
      formik.values.banks[index].accountNumber.length === 10 &&
      formik.values.banks[index].bank.value
    ) {
      const payload = {
        bankCode: formik.values.banks[index].bank.value,
        accountNumber: formik.values.banks[index].accountNumber,
      };
      const bank = await getBankDetails(payload);
      formik.setFieldValue(`banks[${index}].accountName`, bank.acccountName);
      //stop spinner
      setLoadingSpinners((prev) => {
        const stopLoading = [...prev];
        stopLoading[index] = false;
        return stopLoading;
      });
    }
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="mt-6">
        {formik.values.banks.map((bank, index) => (
          <div key={index} className="mt-10">
            <div className="grid flex-1 grid-cols-1 mt-10 gap-x-14 gap-y-2 sm:grid-cols-2">
              <CustomSelect
                options={(bankList || []).map((bk) => ({
                  value: bk.code,
                  label: bk.name,
                }))}
                selectedOption={{
                  value: bank.bank.value,
                  label: bank.bank.label,
                }}
                handleOptionChange={(option) => {
                  formik.setFieldValue(`banks[${index}].bank`, option);
                }}
                label="Bank Name"
                name={`banks[${index}].bankName`}
                isSearchable
                className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                error={
                  formik.touched.banks
                    ? formik.errors.banks?.[index] && formik.errors.banks.length > 0
                      ? JSON.parse(JSON.stringify(formik.errors.banks[index])).bankName
                      : ''
                    : ''
                }
              />
              <TextField
                name={`banks[${index}].accountNumber`}
                type="text"
                value={formik.values.banks[index].accountNumber}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    formik.setFieldValue(`banks[${index}].accountNumber`, e.target.value);
                    handleGetBankDetails(index);
                    setLoadingSpinners((prev) => {
                      const LoadingSpin = [...prev];
                      LoadingSpin[index] =
                        e.target.value.length === 10 && bank.accountName?.length < 1;
                      return LoadingSpin;
                    });
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value.length > 10) {
                    formik.setFieldError(
                      `banks[${index}].accountNumber`,
                      'Account number cannot exceed 10 digits.'
                    );
                  } else {
                    formik.handleChange(e);
                    handleGetBankDetails(index);
                  }
                }}
                label="Account Number"
                error={
                  formik.touched.banks
                    ? formik.errors.banks?.[index] && formik.errors.banks.length > 0
                      ? JSON.parse(JSON.stringify(formik.errors.banks[index]))
                          .accountNumber
                      : ''
                    : ''
                }
                className="bg-[#FFFFFF8C]"
              />
            </div>

            <div className="grid flex-1 grid-cols-1 mt-3 gap-x-14 gap-y-2 sm:grid-cols-2">
              <TextField
                name={`banks[${index}].accountName`}
                type="text"
                value={bank.accountName}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                label="Account Name"
                disabled
                error={
                  formik.touched.banks
                    ? formik.errors.banks?.[index] && formik.errors.banks.length > 0
                      ? JSON.parse(JSON.stringify(formik.errors.banks[index])).accountName
                      : ''
                    : ''
                }
                className="bg-[#FFFFFF8C]"
                loadingSpinner={loadingSpinners[index]}
              />
              <Button
                type="submit"
                variant="primary"
                size="md"
                label="Save Changes"
                className="h-12 mt-6"
                // disabled={formik.isSubmitting}
                // loading={formik.isSubmitting}
              />
            </div>
          </div>
        ))}
      </form>
      <div
        className="flex items-center mt-8"
        role="button"
        tabIndex={-1}
        onClick={addMoreBank}
      >
        <div className="flex items-center gap-1 mb-4">
          <Icon name={Icons.Add} />
          <span className="text-sm min-w-[170px] font-medium text-[#000000]">
            Add another bank account
          </span>
        </div>
        <div className="ml-3 line" />
      </div>
    </div>
  );
}
