import React from 'react';
import { Modal } from '.';
import { Icon, Icons } from '../Icon';

interface IProps {
  children: React.ReactNode;
  className?: string;
  width?: string;
  onClose: () => void;
  usePadding?: boolean;
}

export const InfoModal: React.FC<IProps> = ({
  children,
  className,
  width,
  onClose,
  usePadding = true,
}) => (
  <Modal>
    <div
      className={`${width} relative z-50 mt-32 flex flex-col cursor-default h-full bg-none mx-auto `}
    >
      <div className="flex flex-col items-end justify-end py-10">
        <button
          className="p-3 rounded-full transition ease-in-out delay-150 bg-blue-800 hover:-translate-y-1 hover:scale-100 hover:bg-indigo-700 duration-300 "
          type="button"
          onClick={onClose}
        >
          <Icon name={Icons.Close} fill="#ffffff" />
        </button>
      </div>
      <div
        className={`${
          usePadding ? 'p-8' : ''
        } bg-white relative rounded-[20px] ${className}`}
      >
        {children}
      </div>
    </div>
  </Modal>
);

InfoModal.defaultProps = {
  className: '',
  width: '',
};
