import { useState } from 'react';
import { Icon, Icons } from '../../../../../Components/Icon';
import Button from '../../../../../Components/Form/Button';
import AddAccountModal from '../../../../../Components/Modal/AddAccount/AddAccountModal';
import SuccessModal from '../../../../../Components/Modal/SuccessModal';
import AddExistingAccountModal from '../../../../../Components/Modal/AddAccount/AddExistingAccountModal';
import withCreatePortal from '../../../../../Components/Hoc/withCreatePortal';

const EnhancedSuccessModal = withCreatePortal(SuccessModal);
const EnhancedAddExistingAccountModal = withCreatePortal(AddExistingAccountModal);
const EnhancedAddAccountModal = withCreatePortal(AddAccountModal);

export default function AddAccount() {
  const [selectedOption, setSelectedOption] = useState<'new' | 'existing' | ''>('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <div className="panel py-20 min-[400px]:px-20 max-[400px]:px-12 bg-white rounded-[20px]">
      <div className="flex flex-col items-center justify-center">
        <div className="text-center">
          <p className="text-xl font-semibold font-inter">Switch Account</p>
          <span className="text-base font-normal text-black/40 font-inter">
            Add a new account or connect an existing account
          </span>
        </div>

        <div className="flex max-[570px]:flex-col gap-6 mt-10">
          <div
            role="button"
            tabIndex={0}
            onClick={() => setSelectedOption('existing')}
            className={`w-full py-6 min-[550px]:px-6 max-[550px]:px-3 text-center max-w-[14rem] bg-white border ${
              selectedOption === 'existing' ? 'border-black' : 'border-black/20'
            } rounded-lg shadow flex flex-col justify-center items-center`}
          >
            <Icon name={Icons.AddUser} />
            <div className="py-5 px-2">
              <h5 className="text-sm font-medium text-black font-inter">
                Sign in to existing account
              </h5>
              <h5 className="mt-3 text-[10px] font-normal text-black/40 font-inter">
                You will be signed out of this account
              </h5>
            </div>
          </div>
          <div
            role="button"
            tabIndex={0}
            onClick={() => setSelectedOption('new')}
            className={`w-full py-6 min-[550px]:px-6 max-[550px]:px-3 text-center max-w-[14rem] bg-white border ${
              selectedOption === 'new' ? 'border-black' : 'border-black/20'
            } rounded-lg shadow flex flex-col justify-center items-center`}
          >
            <Icon name={Icons.AddCircle} />
            <div className="py-5 px-2">
              <h5 className="text-sm font-medium text-black font-inter">
                Create a new account
              </h5>
              <h5 className="mt-3 text-[10px] font-normal text-black/40 font-inter">
                Create a separate account with another email address
              </h5>
            </div>
          </div>
        </div>

        <Button
          type="button"
          variant="primary"
          size="md"
          label="Continue"
          className="mt-10"
          disabled={!selectedOption}
          onClick={() => setShowAddModal(true)}
        />
      </div>

      {showAddModal && selectedOption === 'new' && (
        <EnhancedAddAccountModal
          onCancel={() => {
            setShowAddModal(false);
            setSelectedOption('');
          }}
          onSuccess={() => {
            setShowAddModal(false);
            setShowSuccessModal(true);
          }}
          onSignInClick={() => {
            setShowAddModal(true);
            setSelectedOption('existing');
          }}
        />
      )}

      {showAddModal && selectedOption === 'existing' && (
        <EnhancedAddExistingAccountModal
          onCancel={() => {
            setShowAddModal(false);
            setSelectedOption('');
          }}
          onSuccess={() => {
            setShowAddModal(false);
            setShowSuccessModal(true);
          }}
          onSignUpClick={() => {
            setShowAddModal(true);
            setSelectedOption('new');
          }}
        />
      )}

      {showSuccessModal && selectedOption === 'new' && (
        <EnhancedSuccessModal
          title={
            selectedOption === 'new' ? 'Email Confirmation' : 'Account added successfully'
          }
          description={
            selectedOption === 'new'
              ? 'We have sent an email to confirm the validity of your email address'
              : 'Your product list has been updated'
          }
          cancelText={selectedOption === 'new' ? 'Back' : 'Go to Account'}
          onCancel={() => {
            setShowSuccessModal(false);
            setSelectedOption('');
          }}
        />
      )}
    </div>
  );
}
